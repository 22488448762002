<template>
  <div class="modern-color-theme font-poppins flex flex-col gap-2" data-component-name="VNumber">
    <VSLabel v-if="props.label" :tooltip="props.labelTooltip" :for="id">{{ props.label }}</VSLabel>
    <div
      class="flex items-center rounded-md ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600"
      :class="{
        'text-neutral-400 bg-neutral-150 ring-neutral-200': props.disabled,
        'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled
      }"
    >
      <div v-if="slots.prefix" class="pl-2"><slot name="prefix" /></div>
      <input
        :id="id"
        ref="focusElement"
        type="number"
        :value="modelValue"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :placeholder="props.placeholder"
        :max="props.max"
        :min="props.min"
        :step="props.step"
        class="w-full rounded-md m-[2px] pl-2.5 !border-none focus:ring-0 !shadow-none py-[calc(0.375rem-2px)] shadow-sm placeholder:text-neutral-400 text-sm leading-6"
        :class="{
          'text-neutral-400 bg-neutral-150 ring-neutral-200': props.disabled,
          'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled,
          '[-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none': props.noSpinner,
          'text-center': props.centered
        }"
        @change="handleChange"
        @keydown="handleKeyDown"
      >
      <div v-if="slots.suffix" class="pr-2"><slot name="suffix" /></div>
    </div>
    <VSDescription v-if="props.description">{{ props.description }}</VSDescription>
    <VAlert v-if="validationResult" class="mt-2" dense :type="validationResult[0]" :message="validationResult[1]" />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, useSlots } from 'vue';
import type { ValidationResult } from '../../utils/validations';
import VSDescription from './components/VSDescription.vue'
import VSLabel from './components/VSLabel.vue'
import VAlert from '../dialogs/VAlert.vue'
import { useElementId } from '../../utils/utils';
import { useFocus } from '@component-utils/focus';
import { stopAndPrevent } from '~/features/_abstract/utils/event';
import { Key } from '@avvoka/shared';

defineOptions({
  name: 'VNumber'
})

const slots = useSlots()

const props = withDefaults(
  defineProps<{
    id?: string
    label?: string
    labelTooltip?: string
    description?: string
    placeholder?: string
    disabled?: boolean
    readonly?: boolean
    focus?: boolean
    step?: number
    min?: number
    max?: number
    noSpinner?: boolean
    centered?: boolean
    allowEmpty?: boolean
    validator?: (value: undefined | null | number) => ValidationResult
  }>(),
  {
    id: undefined,
    type: 'text',
    label: undefined,
    labelTooltip: undefined,
    placeholder: undefined,
    description: undefined,
    focus: false,
    validator: undefined,
    min: undefined,
    max: undefined,
    step: undefined,
    noSpinner: false,
    centered: false,
    allowEmpty: false
  }
)

const id = useElementId(props.id)

const modelValue = defineModel<undefined | null | number>({ required: true })

const validationActive = ref(false)
const validationResult = computed(() => props.validator && validationActive.value && props.validator(modelValue.value) || null)

const { focus, focusElement } = useFocus(props.focus)

const handleChange = (event: Event) => {
  const element = event.target as HTMLInputElement | null
  if (!element) return

  if (element.value === '') {
    if (props.allowEmpty) {
      modelValue.value = undefined
    } else {
      let value = props.min ?? 0

      modelValue.value = value

      element.value = value.toString()
    }
  } else {
    let value = parseFloat(element.value)
    
    if (typeof props.step === 'number') {
      value = value - value % props.step
    }

    if (typeof props.max === 'number' && value > props.max) {
      value = props.max
    }

    if (typeof props.min === 'number' && value < props.min) {
      value = props.min
    }

    modelValue.value = value

    element.value = value.toString()
  }
}

const NUMBER_LIKE_CHARACTERS = [
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', ',', '.'
]

const CONTROL_CHARACTERS = [
  Key.Backspace, Key.Delete, Key.ArrowUp, Key.ArrowDown, Key.ArrowLeft,
  Key.ArrowRight, Key.Enter, Key.NumpadEnter, Key.ShiftLeft, Key.ShiftRight, Key.Escape
]

const handleKeyDown = (event: KeyboardEvent) => {
  validationActive.value = true

  const key = event.key

  if (typeof props.min === 'number' && props.min >= 0) {
    // Disallow typing of 'minus' when min is equal or above 0
    if (key === '-') stopAndPrevent(event)
  }

  if (event.ctrlKey || event.altKey || event.metaKey) {
    return
  }

  if (NUMBER_LIKE_CHARACTERS.includes(key) || CONTROL_CHARACTERS.includes(key as Key)) {
    // Let them type
  } else {
    stopAndPrevent(event)
  }
}

defineExpose({
  focus
})
</script>